import { useParams } from "react-router-dom";

import FloatDiv from '@components/FloatDiv'
import CopyRight from './CopyRight'
import Policy from './Policy'


const ServiceSupport = () => {
  const { type } = useParams();
  return (
    <div className="w-full pl160 pr160 bsbx ps" style={{ top: '160rem' }}>

      {type === 'copyright' && <FloatDiv><CopyRight /></FloatDiv>}
      {type === 'policy' && <FloatDiv><Policy /></FloatDiv>}
    </div>
  )
}

export default ServiceSupport
