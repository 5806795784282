import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { apiUrl } from '@utils/envriments'

const Policy = () => {

  const [detail, setDetail] = useState()

  const getApi = () => {
    axios.get(`${apiUrl}/dt-platform/v1/agreement/api/index/detail?type=user_agreement`)
      .then(function (response) {
        setDetail(response?.data?.data)
        localStorage.setItem('policy', JSON.stringify(response?.data?.data))
      })
      .catch(function (error) {
        setDetail(JSON.parse(localStorage.getItem('policy')))
      })
  }

  useEffect(() => {
    getApi()
  }, [])

  return (
    <div className="w-full h1780 back-white pt60 pb60 pl140 pr140 bdrs20 bs">
      <div className="w-full h1600 pb60 overflow-hidden">
        <section className="w-full h-full fs24 lh48 fw400 font-regular text-color overflow-auto pr28 bscx">

          <div dangerouslySetInnerHTML={{ __html: detail?.content }} className="policy"></div>

        </section>
      </div>
    </div >
  )
}

export default Policy
