import { Button, Col, Row, Image } from 'antd'
import './index.less'

import { getEnv, GotoPlatform } from '@utils/envriments'
import { teacherImages, studentImages } from '@utils/appData'
import logo from '@assets/footer-logo.png'

const LayoutFooter = () => {
  return (
    <div className="layout-footer">

      <div className="footer-desc">
        <div className="font-semibold fs40 text-color-footer">颠覆传统教育模式，开始智慧教育时代</div>
        <div className="font-medium fs24 lh48 text-color">灵活多样的部署方式，满足各组织机构的定制化需求</div>
        <Button
          className="footer-btn"
          onClick={() => {
            window.open(GotoPlatform(),'_blank')
          }}
        >进入平台</Button>
      </div>

      <div className="footer-message">
        <Row gutter={[24, 8]}>
          <Col span={5} className="pt20 tl">
            <Image
              className="w235 h67 layout-logo"
              src={logo}
              preview={false}
            />
          </Col>

          <Col span={6} className="pt20 pl20">
            <div className="fs18 mb10">北京东田教育科技有限公司</div>
            <div className="flex">
              <div className="flex1 fs13 lh26">邮箱：service@dtedu.com</div>
              <div className="flex1 fs13 lh26">电话：010-82800168</div>
            </div>
            <div className="flex">
              <div className="flex1 fs13 lh26">传真：010-82800128</div>
              <div className="flex1 fs13 lh26">邮编：100191</div>
            </div>
            <div className="flex">
              <div className="flex1 fs13 lh26">地址：北京市海淀区知春路6号锦秋国际大厦A座8楼</div>
            </div>
          </Col>

          <Col span={7} className="pt20 pl20">
            <div className="fs18 mb10">广东东田教育集团有限公司</div>
            <div className="flex">
              <div className="flex1 fs13 lh26">邮箱：dongtian@dtedu.com</div>
              <div className="flex1 fs13 lh26">电话：020-37883333</div>
            </div>
            <div className="flex">
              <div className="flex1 fs13 lh26">传真：020-37855888</div>
              <div className="flex1 fs13 lh26">邮编：510627</div>
            </div>
            <div className="flex">
              <div className="flex1 fs13 lh26">地址：广州市天河区珠江新城海安路19号东田大厦15楼</div>
            </div>
          </Col>

          <Col span={6} className="tr">
            <div className="ib tc">
              <Image
                className="w130 h130 h67 layout-logo p2 back-white"
                src={teacherImages[getEnv()]?.default}
                preview={false}
              />
              <p className="fs14 lh24 fw400 text-color font-regular mt13">东田乐教APP</p>
            </div>
            <div className="ib tc ml58">
              <Image
                className="w130 h130 h67 layout-logo p2 back-white"
                src={studentImages[getEnv()]?.default}
                preview={false}
              />
              <p className="fs14 lh24 fw400 text-color font-regular mt13">东田乐学APP</p>
            </div>
          </Col>
        </Row>

        <div className="footer-copyright fs14 fw400 lh24 text-color font-regular">
          北京东田教育科技有限公司 版权所有 Copyright© DongTian Education Group Co., LTD
          <br/>
          <div className="ib pointer footer-link" onClick={()=>{
            window.open("https://beian.miit.gov.cn", "_blank")
          }}>京ICP备09033124号-1</div>
        </div>
      </div>
    </div>
  )
}

export default LayoutFooter
