import { useRef, useState, useEffect } from 'react'
import { Progress, Slider } from 'antd'
import { launchFullscreen, exitFullscreen, isFullscreen } from './fullScreen'

import videoImg from '@assets/home/video.png'
import videoSrc from '@assets/home/video.mp4'

const VideoTem = () => {
  const videoRef = useRef()
  const boxRef = useRef()
  const [status, setStatus] = useState(true)

  const [nowDuration, setnowDuration] = useState(0)
  const [duration, setDuration] = useState(0)
  const [sliderShow, setSliderShow] = useState(false)

  useEffect(() => {
    const playFun = (e) => {
      let currentTime = e?.target?.currentTime
      setnowDuration(currentTime)
      if (currentTime >= videoRef.current?.duration) {
        setStatus(true)
        setnowDuration(0)
      }
    }

    const durationFun = (e) => {
      setDuration(videoRef.current?.duration)
    }

    videoRef.current && videoRef.current?.addEventListener("loadedmetadata", durationFun);
    videoRef.current && videoRef.current?.addEventListener("timeupdate", playFun);
    return () => {
      videoRef.current && videoRef.current?.removeEventListener("loadedmetadata", durationFun);
      videoRef.current && videoRef.current?.removeEventListener("timeupdate", playFun);
    }
  }, [])

  const onProgressChange = (e) => {
    if (!videoRef?.current) return
    videoRef.current.currentTime = Math.round(e / 100 * videoRef.current.duration)
    setnowDuration(Math.round(e / 100 * videoRef.current.duration))
    if (e === 100) setStatus(true)
  }

  function deBounce(func, wait) {
    let timeOut = null;
    return function (...args) {
      clearTimeout(timeOut);//一定要清除定时器
      timeOut = setTimeout(() => {
        func(...args);
      }, wait);
    };
  }

  const fullChange = () => {
    if (isFullscreen()) exitFullscreen()
    else {
      launchFullscreen(boxRef.current)
    } // 整个页面进入全屏
  }

  useEffect(() => {
    boxRef.current && boxRef.current.addEventListener('mousemove', deBounce(() => {
      setSliderShow(false)
    }, 3000)
    )
    return () => {
      boxRef.current && boxRef.current.addEventListener('mousemove', deBounce(() => {
        setSliderShow(false)
      }, 3000)
      )
    }
  }, [])



  return (
    <div
      className="px10 bdrs10 bsvideo back-white pr overflow-hidden"
      ref={boxRef}
      onMouseMove={() => setSliderShow(true)}
      onMouseEnter={() => { setSliderShow(true) }}
      onMouseLeave={() => setSliderShow(false)}
    >
      <video
        ref={videoRef}
        className="w-full pr"
        poster={videoImg}
        onClick={() => {
          videoRef?.current?.pause()
          setStatus(true)
        }}
      >
        <source src={videoSrc} type="video/mp4"></source>
      </video>

      {sliderShow === true && (
        <div className="flex"
          style={{
            width: '100%',
            padding: '0 51rem',
            position: 'absolute',
            left: 0,
            bottom: '26rem'
          }}
        >
          <Slider
            style={{ marginTop: '12rem', flex: '1' }}
            tooltipVisible={false}
            value={Math.round(nowDuration / duration * 100)}
            onChange={onProgressChange}
            onAfterChange={onProgressChange}
            trackStyle={{
              backgroundColor: `#3284DD`,
              height: `8rem`,
              borderRadius: `2px`
            }}
            railStyle={{
              backgroundColor: `rgba(0,0,0,0.12)`,
              height: `8rem`,
              borderRadius: `2px`
            }}
            handleStyle={{
              width: '20rem',
              height: '20rem',
              background: '#FFFFFF',
              border: '4rem solid rgba(106,178,255,1)',
              boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.15)',
              top: '-6rem',
              marginTop: '0'
            }}
          />
          <i className="iconfont icon-quanping fs24 text-color-download ml30 pointer" onClick={fullChange}/>
        </div>
      )}
      {status && (
        <div
          className="video-btn ib w140 h140 back-footer-opacity80 bdrs100 ps enter pointer tc"
          onClick={() => {
            videoRef?.current?.play()
            setStatus(false)
          }}
        >
          <i className="fs68 lh140 iconfont icon-anniu_bofang text-color-white" />
        </div>
      )}
    </div>
  )
}

export default VideoTem
