import { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Image, Affix } from 'antd'
import './index.less'

import CarouselTem from '@components/Carousel'
import DropDownTem from '@components/Dropdown'

import { dropDownList } from '@utils/homeData'
import { GotoPlatform } from '@utils/envriments'


import logo from '@assets/logo.png'
import fixLogo from '@assets/footer-logo.png'


const LayoutHeader = ({
  bannerList
}) => {
  const location = useLocation();
  const history = useHistory();

  const [list, setList] = useState([])
  const [isEnter, setIsEnter] = useState(false)
  const [affixed, setAffixed] = useState(false)

  const typeList = (list) => {
    let pathname = location?.pathname?.substr(location?.pathname.indexOf('/') + 1)
    let path = pathname?.split('/')
    let newList = JSON.parse(JSON.stringify(list))
    path?.forEach((el, i) => {
      newList = newList[el]
    })
    setList(newList)
  }

  useEffect(() => {
    typeList(bannerList)
  }, [bannerList, location])

  return (
    <>
      <Affix offsetTop='0' onChange={(affixed)=>setAffixed(affixed)}>
        <div
          className={classNames("layout-header flex", {
            'back-white': isEnter
          })}
          onMouseEnter={() => setIsEnter(true)}
          onMouseLeave={() => setIsEnter(false)}
        >
          <Image
            className="ib w140 h40 layout-logo pointer"
            src={(affixed || isEnter) ? fixLogo : logo}
            preview={false}
            onClick={()=>{
              history.push('/home')
            }}
          />
          <div className="layout-menu flex1">
            {dropDownList?.map((el, i) => (
              <DropDownTem key={i} dropItem={el} isEnter={isEnter}/>
            ))}
          </div>

          <div className="ib">
            <div
              className={classNames("enter-plat fs16 font-medium lh16 fw500  bdrs20 pt11 pb11 pl37 pr37 mt22 mt22 pointer",{
                'text-color-white bd1F': !isEnter,
                'text-color-download bd1P': isEnter
              })}
              onClick={() => {
                window.open(GotoPlatform(), "_blank")
              }}
            >
              进入平台
            </div>
          </div>
        </div>
      </Affix>
      <CarouselTem list={list} />
    </>
  )
}

export default LayoutHeader
