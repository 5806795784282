import { useHistory } from "react-router-dom";
import { Button, Carousel, Image } from 'antd';
import './index.less'
import classNames from 'classnames';

const CarouselTem = ({ list }) => {

  const history = useHistory();

  const jumpDom = (url) => {
    const httpRegx = /^http(s*):\/\//
    if(!url) return
    if (httpRegx.test(url)) {
      window.open(url, "_blank")
    } else {
      history.push(url)
    }
  }

  return (
    <Carousel
      className="carousel-box"
      autoplay={list?.length > 1 ? true : false}
      dots={true}
      autoplaySpeed={5000}
    >
      {list?.map((el, i) => (
        <div className="pr" key={i} >
          <div className="w-full" style={{background: el.background, height: el.height+'rem'}}></div>

          {el?.image?.map((item,idex)=>(
            <img key={idex} src={item?.src?.default} className="ps" style={item.position} />
          ))}

          <div className={classNames("carousel-desc",{
            'pb160': list?.length > 1,
            'left200': !!el?.operate,
            'left303': !el?.operate
          })}>
            <div className="fs56 text-color-white fw500 lh60 font-medium">
              {el?.title}
            </div>

            <div className="fs40 text-color-white fw500 lh60 font-medium mt24">
              {el?.subtitle}
            </div>

            {el?.operate && (
              <Button
                className={`w274 h71 fs32 lh32 fw400 font-regular text-color-white bdrs60 mt56 bd1F back-white-opacity16 ${el?.operate?.iconClass}`}
                icon={<i className={`${el?.operate?.icon} iconfont fs36 mr12`} />}
                onClick={()=>{
                  jumpDom(el?.operate?.path)
                }}
              >
                {el?.operate?.name}
              </Button>
            )}
          </div>
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselTem
