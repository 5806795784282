import React, { Suspense, useEffect } from "react";
import { Route, Switch, Redirect, HashRouter, useHistory } from "react-router-dom";
import { Skeleton, Layout, Image, BackTop } from 'antd'
import axios from 'axios'

import LayoutHeader from '@components/LayoutHeader'
import LayoutFooter from '@components/LayoutFooter'
import Home from '@pages/Home'
import Product from '@pages/Product'
import ServiceSupport from '@pages/ServiceSupport'
import Download from '@pages/APP'

import { layoutBanner } from '@utils/homeData'
import { apiUrl } from '@utils/envriments'

import scrollTop from '@assets/scroll-top.png'

const SwithRoute = () => {
  const history = useHistory();

  const sendReport = (route) => {
    axios.post(`${apiUrl}/dt-platform/v1/collector/report-inner/website-view`, {
      route: route
    })
  }

  useEffect(() => {
    sendReport(history?.location?.pathname)
    history?.listen((location) => {
      sendReport(location?.pathname)
      //控制每次跳转滚动条都在顶部
      if (window?.scrollTo) window.scrollTo(0, 0)
      if (document?.scrollTo) document.scrollTo(0, 0)
    })
  }, [])

  return (
    <Layout>
      <Layout.Header className="ant-layout-header">
        <LayoutHeader bannerList={layoutBanner} />
      </Layout.Header>
      <Layout.Content>
        <Switch>
          <Route component={Home} path="/home" />
          <Route component={Product} path="/product/:type" />
          <Route component={ServiceSupport} path="/servicesupport/:type" />
          <Route component={Download} path="/download" />
          <Redirect to="/home" />
        </Switch>
      </Layout.Content>
      <Layout.Footer>
        <LayoutFooter />
      </Layout.Footer>
      <BackTop>
        <Image
          className="w72 h72"
          src={scrollTop}
          preview={false}
        />
      </BackTop>
    </Layout>
  )
}

const Router = () => {

  return (
    <Suspense fallback={<Skeleton paragraph={{ rows: 16 }} active={true} />}>
      <HashRouter>
        <SwithRoute />
      </HashRouter>
    </Suspense>
  );
};

export default Router;
