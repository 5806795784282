// 是否支持 a 标签的 download 属性
const isSupportDownload = 'download' in document.createElement('a')

const token = window.localStorage.getItem('token')

export const paramsToQuery = (params = {}) => {
  const data = Object.keys(params)
    .filter(key => params[key] !== undefined)
    .map(key => `${key}=${params[key]}`)

  !params.jwt && data.push(`jwt=${token}`)

  return data.join('&')
}

/**
 *
 * @param {string} url 接口地址
 * @param {object} params 参数
 */
export default function downloadFromUrl(url, params = {}) {
  if (!url) return
  const _url = `${url}?${paramsToQuery(params)}`
  if (isSupportDownload) {
    const eleLink = document.createElement('a')
    eleLink.href = _url
    eleLink.download = params.fileName || true
    eleLink.style.display = 'none'
    // 触发点击
    document.body.appendChild(eleLink)
    eleLink.click()
    // 然后移除
    eleLink.remove()
  } else {
    window.open(_url)
  }
}
