
const CopyRight = () => {
  return (
    <div className="back-white pt60 pb60 pl140 pr140 bdrs20 bs">
      <div className="fs40 lh40 fw500 font-medium text-color-footer pb60 bdb1E tc">版权声明</div>
      <section className="fs24 lh48 fw400 font-regular text-color pt60">
        <p>1、除非东田教育另行声明外，由东田教育运营的东田基础教育综合平台(以下简称“本产品”)及客户端内所有产品、技术、软件、程序、数据及其他信息(包括文字、图标、图片、照片、音频、视频、图表、色彩组合、版面设计、商标、商号、域名等)的所有权利(包括但不限于著作权、商标权、专利权、商业秘密等相关权利)均属东田教育所有。未经东田教育书面许可，任何单位和个人不得擅自摘抄、复制文档的部分或全部，并以任何形式传播。</p>

        <p>2、东田教育拥有东田基础教育综合平台网站内所有资料所包含的受知识产权或其他法律保护的资料享有相应的权利。“所有资料”系指东田教育编制的软件文字说明及截取的软件运行图片等属于其专有內容、原创内容和其他通过授权取得的独家或非独家内容等。</p>

        <p>3、未经东田教育的明确书面许可，任何人不得将任何资料(包括但不限于文字、图片、软件、属于本站的下载链接等)在互联网上转载、链接、变更、发行、传播、复制、重制、改动、以其他方式复制发布/发表、衍生其他作品或用于任何商业用途，且不得以任何形式展示本网站及所属软件和/或截取网站及所属软件而形成的图像。对不遵守本声明或其他违法、恶意使用本网站内容者，东田教育保留追究其法律责任的权利。</p>

        <p>4、凡以任何方式登陆本网站或直接、间接使用本网站资料者，视为自愿接受东田教育本项声明的约束。</p>
      </section>
    </div>
  )
}

export default CopyRight
