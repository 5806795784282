import { useState, useEffect } from 'react';
import { Button, Image } from 'antd';
import axios from 'axios'
import './index.less'

import FloatDiv from '@components/FloatDiv'
import CarouselTem from '@components/Carousel'
import { bannerList, itemList } from '@utils/appData'

import downloadFromUrl from '@utils/downloadFromUrl'
import { apiUrl } from '@utils/envriments'

const sendDownloadReport = (name) => {
  axios.post(`${apiUrl}/dt-platform/v1/collector/report-inner/website-download`, {
    client: 'pc',
    name: name
  })
}


const Description = ({
  item,
  downloadUrl
}) => {

  return (
    <>
      <div className="fs48 fw700 font-bold text-color-white bdb1F pb23">
        <Image
          className="ib w58 h58 mr14"
          src={item?.src?.default}
          preview={false}
        />
        {item?.title}

        <div className="fs32 fw400 mt13">
          {item?.subtitle}
        </div>
      </div>

      <div className="mt24 mb40">
        {item?.content?.map((el, i) => (
          <div
            key={i}
            className="fs20 lh40 fw400 font-regular text-color-white"
          >
            {el}
          </div>
        ))}
      </div>

      <div className="flex">
        <Image
          className="ib w120 h120 p2 back-white"
          src={item?.operate?.qrcode?.default}
          preview={false}
        />

        <div className="flex1 ml32 flex flex-col flex-between pt6 pb6">
          {item?.operate?.button?.map((el, i) => {
            if (el?.type === 'horizontal') {
              return (
                <div key={i}>
                  {el?.list?.map((im, idex) => (
                    <Button
                      key={i + idex.toString()}
                      className={`w182 h44 vam fw400 font-regular bdrs60 bd1F mr20 fs16 lh26 ${im.iconClass}`}
                      onClick={() => {
                        if (i !== 0) {
                          sendDownloadReport(item?.title)
                          downloadFromUrl(downloadUrl)
                        }
                      }}
                    >
                      <i className={`${im?.icon} fs24 iconfont mr8 vam`} />
                      <span className="vam lh19">{im?.name}</span>
                    </Button>
                  ))}
                </div>
              )
            } else {
              return el?.list?.map((im, idex) => (
                <Button
                  key={i + idex.toString()}
                  className={`w182 h44 vam fw400 font-regular bdrs60 bd1F fs16 lh26 ${im.iconClass}`}
                >
                  <i className={`${im?.icon} fs24 iconfont mr8 vam`} />
                  <span className="vam lh19">{im?.name}</span>
                </Button>
              ))
            }
          })}
        </div>
      </div>
    </>
  )
}

const Download = () => {

  const [detail, setDetail] = useState()

  const getVersionInfo = () => {
    axios.get(`${apiUrl}/dt-platform/v1/version/api/update-info?device=pc`)
      .then(function (response) {
        setDetail(response?.data?.data)
        localStorage.setItem('pcVersion', JSON.stringify(response?.data?.data))
      }).catch(function (error) {
        setDetail(JSON.parse(localStorage.getItem('pcVersion')))
      })
  }

  useEffect(() => {
    getVersionInfo()
  }, [])


  return (<>
    {itemList?.map((item, index) => (
      <div key={index} className="pr">
        <CarouselTem list={[bannerList[index]]} />
        <div className={item?.wrapperClass}>
          <FloatDiv>
            <Description item={item} downloadUrl={detail?.path_link || detail?.path_package} />
          </FloatDiv>
        </div>
      </div>
    ))}
  </>)
}

export default Download
