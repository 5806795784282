import classNames from 'classnames'
import { Image } from 'antd';
import { useRef, useState } from 'react';
import './index.less'

import EduCarousel from './Carousel'
import { educationList } from '@utils/homeData'

const Educator = () => {
  const carouselRef = useRef()

  const [active, setActive] = useState(0)

  return (
    <div className="educator pl75 pr75">
      <div className="fs40 lh40 fw500 text-color-footer font-medium tc pt110">
        教育专家智囊团
      </div>

      <div className="fs24 lh20 fw400 text-color font-regular tc pt32">
        500+教育专家团队，包括全国著名特级教师、一线骨干教师、教研人员、题库专家等
      </div>

      <div className="flex">
        <div className="educator-active tc mt120">
          <EduCarousel carouselRef={carouselRef} list={educationList} setActive={setActive}/>
        </div>
        <div className="flex1 pr">
          {educationList?.map((el, i) => (
            <li
              key={i}
              className={classNames(`pointer educator-li educator-li${i + 1}`,{
                'educator-scale': i === active
              })}
              onClick={()=>{
                carouselRef.current.goTo(i)
              }}
            >
              <Image
                className={classNames(" bdrs60 overflow-hidden", {
                  'bd4F back-white': i !== active,
                  'bd4B back-border': i === active,
                })}
                src={el?.avatar?.default}
                preview={false}
              />
            </li>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Educator
