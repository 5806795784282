import { getEnv } from '@utils/envriments'
const env = getEnv()

const teacherImages = {
  dev: require(`@assets/app/teacher-dev.png`),
  test: require(`@assets/app/teacher-test.png`),
  pre: require(`@assets/app/teacher-pre.png`),
  prd: require(`@assets/app/teacher-prd.png`)
}

const studentImages = {
  dev: require(`@assets/app/student-dev.png`),
  test: require(`@assets/app/student-test.png`),
  pre: require(`@assets/app/student-pre.png`),
  prd: require(`@assets/app/student-prd.png`)
}

const bannerList = [
  {
    background: 'linear-gradient(135deg, #4FACEE 0%, #2872DD 100%)',
    height: '900',
    image: [
      {
        src: require('@assets/app/banner1-cont1.png'),
        position: {
          width: '400rem',
          top: 0,
          left: '200rem'
        }
      },{
        src: require('@assets/app/banner1-cont2.png'),
        position: {
          width: '322rem',
          top: 0,
          right: 0
        }
      },{
        src: require('@assets/app/banner1-cont3.png'),
        position: {
          width: '850rem',
          bottom: 0,
          left: '96rem'
        }
      },{
        src: require('@assets/app/banner1-cont4.png'),
        position: {
          width: '560rem',
          bottom: 0,
          right: 0
        }
      },{
        src: require('@assets/app/banner1-cont5.png'),
        position: {
          width: '916rem',
          top: '165rem',
          left: '141rem'
        }
      }
    ],
    title: '',
    subtitle: '',
  }, {
    background: 'linear-gradient(135deg, #48B379 1%, #49CF9D 100%)',
    height: '900',
    image: [
      {
        src: require('@assets/app/banner2-cont1.png'),
        position: {
          width: '322rem',
          top: 0,
          left: 0
        }
      },{
        src: require('@assets/app/banner2-cont2.png'),
        position: {
          width: '400rem',
          top: 0,
          right: '200rem'
        }
      },{
        src: require('@assets/app/banner2-cont3.png'),
        position: {
          width: '560rem',
          bottom: 0,
          left: 0
        }
      },{
        src: require('@assets/app/banner2-cont4.png'),
        position: {
          width: '850rem',
          bottom: 0,
          right: '96rem'
        }
      },{
        src: require('@assets/app/banner2-cont5.png'),
        position: {
          width: '634rem',
          top: '151rem',
          right: '246rem'
        }
      }
    ],
    title: '',
    subtitle: '',
  }
]

const itemList = [
  {
    src: require('@assets/app/banner1-logo.png'),
    title: '东田乐教',
    subtitle: '智慧教学及校园管理系统',
    content: [
      '• 依据大数据分析，协助教育局及学校进行校园管理与科学决策',
      '• 贴合教师“课前、课中、课后”教学场景，提供海量精品教学资源',
      '• 提供课程时间表、智能备授课、组卷作业、学情分析等个性化教学功能'
    ],
    operate: {
      qrcode: teacherImages[env],
      button: [
        {
          type: "horizontal", // 行
          list: [
            {
              icon: 'icon-xiazai_anniu_ios',
              name: 'iOS下载',
              iconClass: 'default text-color-download back-white bd-color'
            }, {
              icon: 'icon-xiazai_anniu_Android',
              name: '安卓下载',
              iconClass: 'default back-white-opacity16 white'
            }
          ],
        }, {
          type: "horizontal", // 行
          list: [
            {
              icon: 'icon-a-xiazai_anniu_iosbeifen3',
              name: '客户端下载',
              iconClass: 'pointer text-color-pc'
            }
          ]
        }
      ]
    },
    wrapperClass: 'download-right'
  }, {
    src: require('@assets/app/banner2-logo.png'),
    title: '东田乐学',
    subtitle: '家校共育及个性化学习系统',
    content: [
      '• 依托知识图谱技术，引导学生进行自主学习、查漏补缺，形成学习闭环',
      '• 提供课程时间表、答疑解惑、作业、测评、错题本等智慧学习功能',
      '• 家校协同，实时同步学情，共同督促学生学习'
    ],
    operate: {
      qrcode: studentImages[env],
      button: [
        {
          type: "vertical", // 竖
          list: [
            {
              icon: 'icon-xiazai_anniu_ios',
              name: 'iOS下载',
              iconClass: 'default text-color-student back-white bd-color'
            }, {
              icon: 'icon-xiazai_anniu_Android',
              name: '安卓下载',
              iconClass: 'default back-white-opacity16 white'
            }
          ]
        }
      ]
    },
    wrapperClass: 'download-left'
  }
]

export { bannerList, itemList, teacherImages, studentImages  }
