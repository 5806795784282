import { useHistory, useLocation } from "react-router-dom";
import { useRef, useState } from "react";
import classNames from "classnames";
import { Dropdown } from 'antd';

const DropDownTem = ({
  dropItem,
  isEnter,
}) => {
  const history = useHistory();

  const [visible, setVisible] = useState(false)
  const [mouseType, setMouseType] = useState(null)

  const jumpDom = (url, title) => {
    const httpRegx = /^http(s*):\/\//
    if (!url) return
    if(title) document.title = title
    if (httpRegx.test(url)) {
      window.open(url, "_blank")
    } else {
      history.push(url)
    }
  }

  const menu = (
    <>
      {dropItem?.list && (
        <div className="w420 back-white bsdown bdrs10 pt24 pb14 pl24 pr24 overflow-hidden">
          <div className={classNames("fs16 fw400 font-regular bdb1E pb16 lh16 text-color-footer")}>
            {dropItem?.name}
          </div>

          <div className="w-full pt10">
            {dropItem?.list?.map((el, i) => (
              <div
                className={classNames("ib w186 bsbx mt4 pr20")}
                key={i}
                onClick={() => {
                  jumpDom(dropItem?.path + '/' + el?.type, el?.documentTitle)
                }}
              >

                <div
                  className={classNames("px10 pointer bdrs8", {
                    'back-active': mouseType === el?.type
                  })}
                  onMouseEnter={() => setMouseType(el?.type)}
                  onMouseLeave={() => setMouseType(null)}
                >
                  <div className="font-medium fs14 text-color-footer fw500 vam">
                    <i
                      className="ib mr6 bdrs8 w8 h8 vam mt-4"
                      style={{ background: el?.color }}
                    />
                    {el.name}
                  </div>

                  <div className="fs14 fw400 text-color font-regular">
                    {el.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['hover']}
      destroyPopupOnHide={true}
      getPopupContainer={() => document.getElementById('area')}
      onVisibleChange={(visible)=>{
        setVisible(visible)
      }}
    >
      <div
        id="area"
        className={classNames("ib h80 fs16 font-medium lh80 fw500 mr80 pointer",{
          'text-color': isEnter,
          'text-color-download': visible,
          'text-color-white': !isEnter && !visible
        })}
        onClick={() => {
          if (!dropItem?.list) jumpDom(dropItem?.path, dropItem?.documentTitle)
        }}
      >
        {dropItem?.title}
        {dropItem?.list?.length > 0 &&
          <i className={classNames("iconfont fs16 ml9", {
            'icon-daohang_xiala_weixuan': !visible,
            'icon-daohang_xiala_yixuan': visible
          })} />
        }
      </div>
    </Dropdown>
  )
}

export default DropDownTem
