
const productData = {
  manager: {
    proCard: [
      {
        src: require('@assets/product/manager/manager1.png'),
        title: '全方位校园办公自动化',
        color: '#5D7296',
        content: [
          '全面的人，事，物管理',
          '横跨行政，教务，资产，后勤等等全方位的校园自动化',
          '多种流程的无纸化办公',
          '灵活多变的行政结构及审批流程',
          '工作流引擎保证高效率协作',
          '统一时间及事件管理'
        ]
      }, {
        src: require('@assets/product/manager/manager2.png'),
        title: '省，地区，市，学校数据集中化',
        color: '#5D7296',
        content: [
          '多层次多维度的数据采集',
          '支持各种数据格式',
          '数据标准化',
          '分布式集中数据存储',
          '各种分层次分级别的数据统计',
          '同级别数据比对成为可能',
        ]
      }, {
        src: require('@assets/product/manager/manager3.png'),
        title: '大数据分析及智能化',
        color: '#5D7296',
        content: [
          '实时展现各种反应现实情况的数据',
          '追溯历史发生的事件及问题',
          '分析原因总结规律',
          '商业智能预测未来行为',
          '辅助决策',
          '人工智能帮助减少重复性工作',
          '多维度，多视角，多层次'
        ]
      }, {
        src: require('@assets/product/manager/manager4.png'),
        title: '更加有效及时的管理和监控',
        color: '#5D7296',
        content: [
          '根据客观数据来辅助管理',
          '多层次多级别的管理及汇总',
          '移动应用使成随时随地管理和监控',
          '系统随时提醒',
          '按需要订阅各种管理监控信息',
          '并由系统随时推送及提醒',
          '各种管理监控报表让问题一览无余'
        ]
      }
    ],
    featCard: [
      {
        image: require('@assets/product/manager/card1.png'),
        title: '学籍管理',
        description: '展示学校日常动态，重要事件在线公布通知'
      },{
        image: require('@assets/product/manager/card2.png'),
        title: '教师管理',
        description: '建立完整的电子化教师信息档案管理系统'
      },{
        image: require('@assets/product/manager/card3.png'),
        title: '请假管理',
        description: '针对学生请假信息的管理维护支持二次审批'
      },{
        image: require('@assets/product/manager/card4.png'),
        title: '校园日历',
        description: '学习的个性化校历，更高效的日程管理'
      },{
        image: require('@assets/product/manager/card5.png'),
        title: '教学计划',
        description: '建立管理体系，制定及维护教师的教学计划'
      },{
        image: require('@assets/product/manager/card6.png'),
        title: '动态公告',
        description: '展示学校日常动态，重要事件在线公布通知'
      }
    ]
  },
  teacher: {
    proCard: [
      {
        src: require('@assets/product/teacher/teacher1.png'),
        title: '智能教学及时间管理',
        color: '#3482E2',
        content: [
          '同步教学及教研安排',
          '专家团队领衔的教学资源库',
          '智能化备课及授课(如分组教学，拍照投屏，',
          '即时推送，自动改题及查漏等)',
          '满足个性化作业，个性化测评',
          '并配套有与知识点关联的海量题库'
        ]
      }, {
        src: require('@assets/product/teacher/teacher2.png'),
        title: '智能教研及教研工作流',
        color: '#3482E2',
        content: [
          '教研任务及时获取',
          '个性化及兴趣化教研任务领取',
          '清晰的教研进程及历史追踪',
          '分级别分层次的教研结果全网共享',
          '支持校内校际协作',
          '基于工作流的任务及依赖关系'
        ]
      }, {
        src: require('@assets/product/teacher/teacher3.png'),
        title: '班级，学生及家长管理及互动',
        color: '#3482E2',
        content: [
          '基于大数据分析结果的智能班级管理',
          '学生及家长信息管理并及时更新',
          '随时随地查看或发布动态及通知',
          '学生或家长一键互联',
          '因材施教，按需施教',
          '安排小组学习',
          '支持一对一在线辅导及作业批改',
          '基于知识图谱的学生智能分析及评价'
        ]
      }, {
        src: require('@assets/product/teacher/teacher4.png'),
        title: '教师办公自动化',
        color: '#3482E2',
        content: [
          '随时随地获取学校通知及动态',
          '申请，审批及委派一键完成',
          '文档，资料精准归档',
          '个人成长及实时动态更新',
          '智能化办公自动化报告',
          '支持教师担任不同角色',
          '按需要订阅各种管理监控信息',
          '并由系统随时推送及提醒'
        ]
      }
    ],
    featCard: [
      {
        image: require('@assets/product/teacher/card1.png'),
        title: '课程时间表',
        description: '教师的个性化时间管理工具，支持任务和日程安排'
      },{
        image: require('@assets/product/teacher/card2.png'),
        title: '备课系统',
        description: '提供丰富的备课资源，助力教师高效完成备课'
      },{
        image: require('@assets/product/teacher/card3.png'),
        title: '授课系统',
        description: '高效的课堂授课，学科工具、课堂评价、随堂练习'
      },{
        image: require('@assets/product/teacher/card4.png'),
        title: '作业测评',
        description: '模拟考试、自由组卷、在线考试、作答统计、查漏补缺'
      },{
        image: require('@assets/product/teacher/card5.png'),
        title: '答疑解惑',
        description: '提供教师与学生之间的答疑平台，促进学习积极性'
      },{
        image: require('@assets/product/teacher/card6.png'),
        title: '班级管理',
        description: '针对教师日常的班级管理，提供各种辅助功能'
      }
    ]
  },
  student: {
    proCard: [
      {
        src: require('@assets/product/student/student1.png'),
        title: '全面的学生时间管理',
        color: '#44AA72',
        content: [
          '同步各科老师的教学计划',
          '同步各科老师的任务 (预习，学习，练习及复习)',
          '个性化自主学习的时间和内容',
          '管理时间冲突，即时有效的时间调整',
          '个人及整体的进度分析',
          '根据学习目标智能生成参考时间表',
          '学生时间管理统计及参考'
        ]
      }, {
        src: require('@assets/product/student/student2.png'),
        title: '个性化学习及测评',
        color: '#44AA72',
        content: [
          '基于知识图谱的教学和测评',
          '多种测评模式(课堂练习，错题强化，模拟考试)',
          '丰富多样与知识图谱关联的题库',
          '随时随地查漏补缺整',
          '基于兴趣爱好的扩展资源推荐',
          '教师，家长和学生同步跟踪',
          '大数据分析及横向对比'
        ]
      }, {
        src: require('@assets/product/student/student3.png'),
        title: '灵活多样的学习模式',
        color: '#44AA72',
        content: [
          '跟随教师的同步学习',
          '课堂中的互动学习',
          '课前课后的自主学习',
          '支持网页，平板和手机',
          '随时随地利用时间片段',
          '在线问老师',
          '小组学习',
          '一对一在线辅导及作业批改'
        ]
      }, {
        src: require('@assets/product/student/student4.png'),
        title: '丰富多样的学生资源库',
        color: '#44AA72',
        content: [
          '系统性学习视频及讲座',
          '教师推送学习资源(联通教师资源库)',
          '自主学习用微课',
          '习题及测评库',
          '分知识点的学习卡片',
          '丰富多彩的参考资源库',
          '持续性增长的学生资源库',
          '强大的顾问团及合作伙伴提供的资源'
        ]
      }
    ],
    featCard: [
      {
        image: require('@assets/product/student/card1.png'),
        title: '课程时间表',
        description: '学生的个性化时间管理工具，提供学习安排和任务管理'
      },{
        image: require('@assets/product/student/card2.png'),
        title: '错题本',
        description: '多学科的错题整理工具，助力学生查漏补缺、掌握知识点'
      },{
        image: require('@assets/product/student/card3.png'),
        title: '同步学习',
        description: '课前预习、课堂互动、课后复习，提供学习任务跟踪统计'
      },{
        image: require('@assets/product/student/card4.png'),
        title: '自主学习',
        description: '教材同步的学习课程，采用“学-练-测-评”学习模式'
      },{
        image: require('@assets/product/student/card5.png'),
        title: '测评',
        description: '基于知识图谱，提供模拟考试、同步练习、知识点强化训练'
      },{
        image: require('@assets/product/student/card6.png'),
        title: '学习轨迹',
        description: '提供全平台学生的学习数据和行为，可查看明细'
      }
    ]
  },
  parent: {
    proCard: [
      {
        src: require('@assets/product/parent/parent1.png'),
        title: '子女动态一览无余',
        color: '#F48F19',
        content: [
          '支持多对多家长与子女信息关联',
          '随时随地查看子女动态',
          '各种内置学习报告',
          '基于知识图谱的子女学力能力分析',
          '校园及班级的通知及动态',
          '子女历史及未来时间安排'
        ]
      },{
        src: require('@assets/product/parent/parent2.png'),
        title: '家校互通，积极参与',
        color: '#F48F19',
        content: [
          '支持手机，平板及电脑',
          '随时随地家校互通',
          '参与子女时间管理',
          '安排其他学习及娱乐',
          '与老师及学校领导沟通一键完成',
          '多位家长间的信息共享'
        ]
      }
    ],
    featCard: [
      {
        image: require('@assets/product/parent/card1.png'),
        title: '课程时间表',
        description: '学生的个性化时间管理工具，提供学习安排和任务管理'
      },{
        image: require('@assets/product/parent/card2.png'),
        title: '请假',
        description: '家长可为孩子申请请假，随时了解审批进度'
      },{
        image: require('@assets/product/parent/card3.png'),
        title: '家校互通',
        description: '家长可以与班级教室及班级家长实时联系'
      },{
        image: require('@assets/product/parent/card4.png'),
        title: '学习记录',
        description: '家长可以查看孩子的学习情况，了解课堂表现-练-测-评”学习模式'
      },{
        image: require('@assets/product/parent/card5.png'),
        title: '诊断分析',
        description: '基于孩子课堂或自学的记录，结合知识图谱进行诊断评估'
      },{
        image: require('@assets/product/parent/card6.png'),
        title: '亲子绑定',
        description: '家长可了解自己绑定的孩子，以及邀请其他家长'
      }
    ]
  },
  platform: {
    proCard: [
      {
        src: require('@assets/product/platform/platform1.png'),
        title: '专业丰富的资源库',
        color: '#009143',
        content: [
          '教育专家领衔制作的海量资源库',
          '经课堂实践并检验过的内容',
          '关联知识图谱及教学大纲的题库',
          '综合分类管理，灵活合成及合成管理',
          '版本控制及历史追溯',
          '一站式数字出版解决方案',
          '综合创建，修改，审批，发布流程'
        ]
      },{
        src: require('@assets/product/platform/platform2.png'),
        title: '先进及创新的信息科学技术',
        color: '#009143',
        content: [
          '多纬度多层次雪片式大数据分析',
          '分布式海量数据云存储',
          '嵌入式人工智能及知识图谱技术',
          '人工智能学习模型智能化教育活动',
          '教育智能及辅助决策',
          '跨平台跨设备响应式前端技术',
          '集成区块链技术的学籍追溯'
        ]
      },{
        src: require('@assets/product/platform/platform3.png'),
        title: '及时，安全，规范，智能',
        color: '#009143',
        content: [
          '集中式身份管理',
          '灵活多变的授权及认证模式',
          '分级数据安全，访问控制及隐私保护',
          '容器化服务器虚拟化及管理',
          '统一的应用及接口',
          '第三方平台及工具集成',
          '实时高效的多级数据缓存',
          '低响应延时，高吞吐量，高性能'
        ]
      },{
        src: require('@assets/product/platform/platform4.png'),
        title: '客户服务，技术支持，管理与监控一体化',
        color: '#009143',
        content: [
          '多层次智能客服及技术坚持',
          '客服知识库及客服机器人保障最快响应别',
          '实时的数据管理及监控',
          '丰富的管理报表',
          '智能监控及实时预警',
          '订阅式系统预警及提醒'
        ]
      }
    ],
    featCard: null
  },
  content: {
    proCard: [
      {
        src: require('@assets/product/content/content1.png'),
        title: '权威教育专家领衔制作',
        color: '#009143',
        content: [
          '特级教师、课程专家、教材专家等共同打造',
          '经课堂实践并检验过的内容',
          '关联知识图谱及教学大纲的题库'
        ]
      },{
        src: require('@assets/product/content/content2.png'),
        title: '数字化教学资源',
        color: '#009143',
        content: [
          '覆盖中小学全学段全学科',
          '关联知识图谱及教学大纲',
          '数量丰富、类型多样的教材资源'
        ]
      }
    ],
    featCard: [
      {
        image: require('@assets/product/content/card1.png'),
        title: '电子课本',
        description: '以纸质教材为蓝本，以多媒体及交互形式呈现教材'
      },{
        image: require('@assets/product/content/card2.png'),
        title: '教学课件',
        description: '精准课件，一个课时一个课件包与最新教材同步'
      },{
        image: require('@assets/product/content/card3.png'),
        title: '教学设计',
        description: '根据不同教材配套多版本教学设计，专业成体系'
      },{
        image: require('@assets/product/content/card4.png'),
        title: '东田网课',
        description: '课程包关联到章节目录中形成课程体系-练-测-评”学习模式'
      },{
        image: require('@assets/product/content/card5.png'),
        title: '名师微课',
        description: '3000+名师精讲实录微课夯实基础'
      },{
        image: require('@assets/product/content/card6.png'),
        title: '真题练习',
        description: '丰富的习题库，同步练习、专题练习、考试真题'
      },{
        image: require('@assets/product/content/card7.png'),
        title: '教学课例',
        description: '为一线教师提供多样化的教学范例'
      },{
        image: require('@assets/product/content/card8.png'),
        title: '演示动画',
        description: '提供实验课例教学，引导学生应用探究知识'
      },{
        image: require('@assets/product/content/card9.png'),
        title: '各类素材',
        description: '包含各类图片、视频、音频等多媒体素材'
      }
    ]
  }
}

const iconList = [
  {
    src: require('@assets/product/manager-icon.png'),
    title: '管理端',
    type: 'manager',
    documentTitle: '管理端 - 东田基础教育综合平台'
  }, {
    src: require('@assets/product/teacher-icon.png'),
    title: '教师端',
    type: 'teacher',
    documentTitle: '教师端 - 东田基础教育综合平台'
  }, {
    src: require('@assets/product/student-icon.png'),
    title: '学生端',
    type: 'student',
    documentTitle: '学生端 - 东田基础教育综合平台'
  }, {
    src: require('@assets/product/parent-icon.png'),
    title: '家长端',
    type: 'parent',
    documentTitle: '家长端 - 东田基础教育综合平台'
  }, {
    src: require('@assets/product/platform-icon.png'),
    title: '基础平台',
    type: 'platform',
    documentTitle: '基础平台 - 东田基础教育综合平台'
  }, {
    src: require('@assets/product/content-icon.png'),
    title: '内容资源系统',
    type: 'content',
    documentTitle: '内容资源系统 - 东田基础教育综合平台'
  }
]

export { productData, iconList }
