import { Carousel, Image } from 'antd';

const EduCarousel = ({ carouselRef, list, setActive }) => {

  return (
    <Carousel
      ref={carouselRef}
      effect="fade"
      autoplay={true}
      dots={false}
      autoplaySpeed={3000}
      beforeChange={(from,to)=>{
        setActive(to)
      }}
    >
      {list?.map((item, i) => (
        <div className="pr" key={i}>
          <Image
            className="w180 h180 bdrs100 mt136 bd4F back-white"
            src={item?.avatar?.default}
            preview={false}
          />

          <div className="fs32 fw500 font-medium text-color-white pt40 pb14">
            {item?.name}
          </div>

          {item?.introduce.map((el, i) => (
            <div key={i} className="fs20 lh32 fw400 text-color-white font-regular">
              {el}
            </div>
          ))}
        </div>
      ))}
    </Carousel>
  )
}

export default EduCarousel
