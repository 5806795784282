import FloatDiv from '@components/FloatDiv'
import ContCard from './ContCard'
import Educator from './Educator'
import VideoTem from './Video'
import { contList } from '@utils/homeData'


const Home = () => {
  return (
    <div className="home">
      <div className="pl200 pr200 mt-282">
        <VideoTem  />
      </div>
      <div className="mb160 pl160 pr160">
        {contList?.map((el, i) => (
          <FloatDiv key={i}>
            <ContCard key={i} item={el} />
          </FloatDiv>
        ))}
      </div>
      <Educator />
    </div>
  );
}

export default Home
