import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

import reportWebVitals from './reportWebVitals';
import Router from "./router";

import './index.less';
import './iconfont'
import './fontSize'

ReactDOM.render(
  <Router />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
