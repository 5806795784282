import { GotoPlatform } from '@utils/envriments'

const layoutBanner = {
  home: [
    {
      background: 'linear-gradient(135deg, #4FACEE 0%, #2872DD 100%)',
      height: '1080',
      image: [
        {
          src: require('@assets/banner1-cont1.png'),
          position: {
            width: '399rem',
            top: 0,
            left: '200rem'
          }
        },{
          src: require('@assets/banner1-cont2.png'),
          position: {
            width: '322rem',
            top: 0,
            right: 0
          }
        },{
          src: require('@assets/banner1-cont3.png'),
          position: {
            width: '850rem',
            bottom: 0,
            left: '97rem'
          }
        },{
          src: require('@assets/banner1-cont4.png'),
          position: {
            width: '562rem',
            bottom: 0,
            right: 0
          }
        },{
          src: require('@assets/banner1-cont5.png'),
          position: {
            width: '891rem',
            top: '116rem',
            right: '202rem'
          }
        }
      ],
      title: '一平台四系统',
      subtitle: '教学更高效，学习更轻松',
      operate: {
        icon: 'icon-banner_anniu_jinrupingtai',
        iconClass: 'blue',
        name: '进入平台',
        path: GotoPlatform()
      }
    }, {
      background: 'linear-gradient(135deg, #5ADC93 0%, #48B379 100%)',
      height: '1080',
      image: [
        {
          src: require('@assets/banner2-cont1.png'),
          position: {
            width: '399rem',
            top: 0,
            left: '200rem'
          }
        },{
          src: require('@assets/banner2-cont2.png'),
          position: {
            width: '322rem',
            top: 0,
            right: 0
          }
        },{
          src: require('@assets/banner2-cont3.png'),
          position: {
            width: '850rem',
            bottom: 0,
            left: '97rem'
          }
        },{
          src: require('@assets/banner2-cont4.png'),
          position: {
            width: '562rem',
            bottom: 0,
            right: 0
          }
        },{
          src: require('@assets/banner2-cont5.png'),
          position: {
            width: '707rem',
            top: '108rem',
            right: '198rem'
          }
        }
      ],
      title: '移动端APP',
      subtitle: '老师的教学管家，学生的学习助手',
      operate: {
        icon: 'icon-banner_anniu_lijixiazai',
        iconClass: 'green',
        name: '立即下载',
        path: '/download'
      }
    }
  ],
  product: {
    manager: [
      {
        background: 'linear-gradient(135deg, #556889 0%, #718AB4 100%)',
        height: '700',
        image: [
          {
            src: require('@assets/product/banner-cont1.png'),
            position: {
              width: '233rem',
              top: 0,
              left: '72rem'
            }
          },{
            src: require('@assets/product/banner-cont2.png'),
            position: {
              width: '74rem',
              top: '344rem',
              left: 0
            }
          },{
            src: require('@assets/product/banner-cont3.png'),
            position: {
              width: '703rem',
              bottom: '89rem',
              left: '237rem'
            }
          },{
            src: require('@assets/product/manager/banner-cont4.png'),
            position: {
              width: '740rem',
              top: '151rem',
              right: '190rem'
            }
          }
        ],
        title: '“管”理端',
        subtitle: '全方位校园办公自动化',
      }
    ],
    teacher: [
      {
        background: 'linear-gradient(135deg, #2872DD 0%, #69BFFC 100%)',
        height: '700',
        image: [
          {
            src: require('@assets/product/banner-cont1.png'),
            position: {
              width: '233rem',
              top: 0,
              left: '72rem'
            }
          },{
            src: require('@assets/product/banner-cont2.png'),
            position: {
              width: '74rem',
              top: '344rem',
              left: 0
            }
          },{
            src: require('@assets/product/banner-cont3.png'),
            position: {
              width: '703rem',
              bottom: '89rem',
              left: '237rem'
            }
          },{
            src: require('@assets/product/teacher/banner-cont4.png'),
            position: {
              width: '769rem',
              top: '93rem',
              right: '191rem'
            }
          }
        ],
        title: '“教”师端',
        subtitle: '教师课堂教学智能化',
      }
    ],
    student: [
      {
        background: 'linear-gradient(135deg, #44AA72 0%, #5CD190 100%)',
        height: '700',
        image: [
          {
            src: require('@assets/product/banner-cont1.png'),
            position: {
              width: '233rem',
              top: 0,
              left: '72rem'
            }
          },{
            src: require('@assets/product/banner-cont2.png'),
            position: {
              width: '74rem',
              top: '344rem',
              left: 0
            }
          },{
            src: require('@assets/product/banner-cont3.png'),
            position: {
              width: '703rem',
              bottom: '89rem',
              left: '237rem'
            }
          },{
            src: require('@assets/product/student/banner-cont4.png'),
            position: {
              width: '880rem',
              top: '108rem',
              right: '46rem'
            }
          }
        ],
        title: '“学”生端',
        subtitle: '学生自主学习体系化',
      }
    ],
    parent: [
      {
        background: 'linear-gradient(135deg, #EC7D32 0%, #FFB281 100%)',
        height: '700',
        image: [
          {
            src: require('@assets/product/banner-cont1.png'),
            position: {
              width: '233rem',
              top: 0,
              left: '72rem'
            }
          },{
            src: require('@assets/product/banner-cont2.png'),
            position: {
              width: '74rem',
              top: '344rem',
              left: 0
            }
          },{
            src: require('@assets/product/banner-cont3.png'),
            position: {
              width: '703rem',
              bottom: '89rem',
              left: '237rem'
            }
          },{
            src: require('@assets/product/parent/banner-cont4.png'),
            position: {
              width: '719rem',
              top: '182rem',
              right: '219rem'
            }
          }
        ],
        title: '“家”长端',
        subtitle: '共建家庭与学校的通道',
      }
    ],
    platform: [
      {
        background: 'linear-gradient(135deg, #0EB25A 0%, #40CE82 100%)',
        height: '700',
        image: [
          {
            src: require('@assets/product/banner-cont1.png'),
            position: {
              width: '233rem',
              top: 0,
              left: '72rem'
            }
          },{
            src: require('@assets/product/banner-cont2.png'),
            position: {
              width: '74rem',
              top: '344rem',
              left: 0
            }
          },{
            src: require('@assets/product/banner-cont3.png'),
            position: {
              width: '703rem',
              bottom: '89rem',
              left: '237rem'
            }
          },{
            src: require('@assets/product/platform/banner-cont4.png'),
            position: {
              width: '760rem',
              top: '168rem',
              right: '211rem'
            }
          }
        ],
        title: '基础平台',
        subtitle: '提供底层的基础功能',
      }
    ],
    content: [
      {
        background: 'linear-gradient(135deg, #0EB25A 0%, #40CE82 100%)',
        height: '700',
        title: '内容资源系统',
        image: [
          {
            src: require('@assets/product/banner-cont1.png'),
            position: {
              width: '233rem',
              top: 0,
              left: '72rem'
            }
          },{
            src: require('@assets/product/banner-cont2.png'),
            position: {
              width: '74rem',
              top: '344rem',
              left: 0
            }
          },{
            src: require('@assets/product/banner-cont3.png'),
            position: {
              width: '703rem',
              bottom: '89rem',
              left: '237rem'
            }
          },{
            src: require('@assets/product/content/banner-cont4.png'),
            position: {
              width: '740rem',
              top: '161rem',
              right: '214rem'
            }
          }
        ],
        subtitle: '涵盖小初高全学科',
      }
    ],
  },
  download: [],
  servicesupport: {
    copyright: [
      {
        background: 'linear-gradient(135deg, #2872DD 0%, #4FACEE 100%)',
        height: '1282',
        image: [
          {
            src: require('@assets/service/banner-cont1.png'),
            position: {
              width: '516rem',
              top: 0,
              left: 0
            }
          },{
            src: require('@assets/service/banner-cont2.png'),
            position: {
              width: '322rem',
              top: 0,
              right: 0
            }
          }
        ],
        title: '',
        subtitle: '',
      }
    ],
    policy: [
      {
        background: 'linear-gradient(135deg, #2872DD 0%, #4FACEE 100%)',
        height: '2029',
        image: [
          {
            src: require('@assets/service/banner-cont1.png'),
            position: {
              width: '516rem',
              top: 0,
              left: 0
            }
          },{
            src: require('@assets/service/banner-cont2.png'),
            position: {
              width: '322rem',
              top: 0,
              right: 0
            }
          }
        ],
        title: '',
        subtitle: '',
      }
    ],
    manual: [
      {
        background: 'linear-gradient(135deg, #2872DD 0%, #4FACEE 100%)',
        height: '1114',
        image: [
          {
            src: require('@assets/service/banner-cont1.png'),
            position: {
              width: '516rem',
              top: 0,
              left: 0
            }
          },{
            src: require('@assets/service/banner-cont2.png'),
            position: {
              width: '322rem',
              top: 0,
              right: 0
            }
          }
        ],
        title: '',
        subtitle: '',
      }
    ]
  }
}

const dropDownList = [
  {
    title: '首页',
    path: '/home',
    documentTitle: '东田基础教育综合平台'
  }, {
    title: '产品介绍',
    path: '/product',
    name: '产品',
    list: [
      {
        name: '管理端',
        type: 'manager',
        description: '全方位校园自动化',
        color: '#5D7296',
        documentTitle: '管理端 - 东田基础教育综合平台'
      }, {
        name: '教师端',
        type: 'teacher',
        description: '教师课堂教学智能化',
        color: '#3284DD',
        documentTitle: '教师端 - 东田基础教育综合平台'
      }, {
        name: '学生端',
        type: 'student',
        description: '学生自主学习体系化',
        color: '#44AA72',
        documentTitle: '学生端 - 东田基础教育综合平台'
      }, {
        name: '家长端',
        type: 'parent',
        description: '共建家校协助的通道',
        color: '#F48F19',
        documentTitle: '家长端 - 东田基础教育综合平台'
      }, {
        name: '内容资源系统',
        type: 'content',
        description: '全学段学科的教学资源',
        color: '#009143',
        documentTitle: '内容资源系统 - 东田基础教育综合平台'
      }, {
        name: '基础平台',
        type: 'platform',
        description: '底层基础功能支撑体系',
        color: '#009143',
        documentTitle: '基础平台 - 东田基础教育综合平台'
      }
    ]
  }, {
    title: '软件下载',
    path: '/download',
    documentTitle: '软件下载 - 东田基础教育综合平台'
  }, {
    title: '服务支持',
    path: '/servicesupport',
    name: '服务支持',
    list: [
      {
        name: '版权声明',
        type: 'copyright',
        description: '东田教育权利所有',
        color: '#5D7296',
        documentTitle: '版权声明 - 东田基础教育综合平台'
      }, {
        name: '用户协议',
        type: 'policy',
        description: '用户协议及隐私政策',
        color: '#3284DD',
        documentTitle: '用户协议 - 东田基础教育综合平台'
      }
    ]
  }, {
    title: '关于东田',
    path: 'https://www.dtedu.com/'
  }
]

const contList = [
  {
    title: '一平台四系统',
    subtitle: '基础平台 + 管理端 + 教师端 + 学生端 + 家长端',
    list: [
      {
        type: 'video',
        poster: require('@assets/home/system.jpg'),
        src: require('@assets/home/system.mp4')
      }
    ]
  }, {
    title: '',
    list: [
      {
        type: 'dest',
        name: '“管”理端',
        color: '#5D7296',
        path: '/product/manager',
        content: [
          '全方位的校园办公自动化',
          '省、市、区、学校数据集中化',
          '大数据分析及科学决策',
          '及时有效的管理和监控'
        ]
      }, {
        type: 'image',
        src: require('@assets/home/manager.png')
      }
    ]
  }, {
    title: '',
    subtitle: '',
    list: [
      {
        type: 'image',
        src: require('@assets/home/teacher.png')
      }, {
        type: 'dest',
        name: '“教”师端',
        color: '#3284DD',
        path: '/product/teacher',
        content: [
          '智能教学及时间管理',
          '智能教研及教研工作流',
          '班级、学生及家长管理及互动',
          '教师办公自动化'
        ]
      }
    ]
  }, {
    title: '',
    subtitle: '',
    list: [
      {
        type: 'dest',
        name: '“学”生端',
        color: '#44AA72',
        path: '/product/student',
        content: [
          '全面的学生时间管理',
          '个性化学习及测评',
          '灵活多样的学习模式',
          '丰富多样的学生资源库'
        ]
      }, {
        type: 'image',
        src: require('@assets/home/student.png')
      }
    ]
  }, {
    title: '',
    subtitle: '',
    list: [
      {
        type: 'image',
        src: require('@assets/home/parent.png')
      }, {
        type: 'dest',
        name: '“家”长端',
        color: '#F48F19',
        path: '/product/parent',
        content: [
          '子女动态一览无余',
          '家校互通，积极参与',
          '一键在线请假，方便快捷',
          '监督孩子学习'
        ]
      }
    ]
  }, {
    title: '',
    subtitle: '',
    list: [
      {
        type: 'dest',
        name: '基础平台',
        color: '#009143',
        path: '/product/platform',
        content: [
          '专业丰富的资源库，内容管理及数字出版',
          '先进及创新的信息科学技术',
          '及时，安全，规范，智能',
          '客户服务，技术支持，管理与监控一体化'
        ]
      }, {
        type: 'image',
        src: require('@assets/home/platform.png')
      }
    ]
  }, {
    title: '',
    subtitle: '',
    list: [
      {
        type: 'image',
        src: require('@assets/home/content.png')
      }, {
        type: 'dest',
        name: '内容资源系统',
        color: '#009143',
        path: '/product/content',
        content: [
          '涵盖小学、初中、高中全学科',
          '特级教师团队指导编辑',
          '海量内容资源，应有尽有',
          '丰富的资源类型'
        ]
      }
    ]
  }
]

const educationList = [
  {
    avatar: require('@assets/home/educator1.png'),
    name: '吴正宪',
    introduce: [
      '全国著名小学数学特级教师',
      '第十二届全国人大代表',
      '教育部中小学教材审查委员会审查委员'
    ]
  }, {
    avatar: require('@assets/home/educator2.png'),
    name: '华应龙',
    introduce: [
      '全国著名小学数学特级教师',
      '国家级骨干教师',
      '北京首批“首都基础教育名家”'
    ]
  }, {
    avatar: require('@assets/home/educator3.png'),
    name: '徐斌',
    introduce: [
      '全国著名小学数学特级教师',
      '国家级骨干教师',
      '苏州大学实验学校副校长'
    ]
  }, {
    avatar: require('@assets/home/educator4.png'),
    name: '武琼',
    introduce: [
      '全国著名小学语文特级教师',
      '北京小学语文学科带头人',
      '教育部中小学教材审查委员会审查委员'
    ]
  }, {
    avatar: require('@assets/home/educator5.png'),
    name: '吉春亚',
    introduce: [
      '全国著名小学语文特级教师',
      '北京小学语文学科带头人'
    ]
  }, {
    avatar: require('@assets/home/educator6.png'),
    name: '刘红',
    introduce: [
      '全国著名小学语文特级教师',
      '南京师范大学硕士生导师',
      '南京市府西街小学校长'
    ]
  }, {
    avatar: require('@assets/home/educator7.png'),
    name: '吴悦心',
    introduce: [
      '江苏省外语教育专业委员会理事',
      '人教版小学英语（PEP）教材副主编'
    ]
  }, {
    avatar: require('@assets/home/educator8.png'),
    name: '刘兆义',
    introduce: [
      '全国著名英语特级教师',
      '国家《英语课程标准》研制组核心成员',
      '享受国务院颁发的政府特殊津贴专家'
    ]
  }, {
    avatar: require('@assets/home/educator9.png'),
    name: '梁捷',
    introduce: [
      '全国中学语文特级教师',
      '教育部基础教育课程教材专家',
      '工作委员会委员'
    ]
  }, {
    avatar: require('@assets/home/educator10.png'),
    name: '吴惟粤',
    introduce: [
      '全国中学语文特级教师',
      '广东省教育厅教育教学研究室原主任',
      '华南师范大学硕士生导师'
    ]
  },{
    avatar: require('@assets/home/educator11.png'),
    name: '杜朝凤',
    introduce: [
      '全国中全国著名中学语文特级教师学语文特级教师',
      '天津市语文学科带头人',
      '天津市教研室兼职教研员'
    ]
  },{
    avatar: require('@assets/home/educator12.png'),
    name: '李庾南',
    introduce: [
      '全国著名中学数学特级教师',
      '第九届全国人大代表',
      '享受国务院颁发的政府特殊津贴专家'
    ]
  },{
    avatar: require('@assets/home/educator13.png'),
    name: '张雁',
    introduce: [
      '全国著名中学数学特级教师',
      '“人教版”义务教育课程标准教科书',
      '初中数学编写专家组成员'
    ]
  },{
    avatar: require('@assets/home/educator14.png'),
    name: '常毓喜',
    introduce: [
      '全国著名中学数学特级教师',
      '国家级骨干教师',
      '北京市优秀教师、金牌教师'
    ]
  },{
    avatar: require('@assets/home/educator15.png'),
    name: '彭梦华',
    introduce: [
      '全国著名中学物理特级教师',
      '北京师范大学硕士生导师',
      '国际奥林匹克物理竞赛金牌老师'
    ]
  },{
    avatar: require('@assets/home/educator16.png'),
    name: '马桂君',
    introduce: [
      '全国著名中学物理特级教师',
      '北京市物理学科带头人',
      '北京市级中青年骨干教师'
    ]
  },{
    avatar: require('@assets/home/educator17.png'),
    name: '白无瑕',
    introduce: [
      '全国著名中学化学特级教师',
      '北京市化学学科带头人',
      '高考命题专家'
    ]
  },{
    avatar: require('@assets/home/educator18.png'),
    name: '周业虹',
    introduce: [
      '全国著名中学化学特级教师',
      '北京市化学学科带头人',
      '北京东城教研室'
    ]
  },{
    avatar: require('@assets/home/educator19.png'),
    name: '林祖荣',
    introduce: [
      '全国著名中学生物特级教师',
      '北京师范大学基础教育研究员',
      '人教版高中教材编写专家'
    ]
  },{
    avatar: require('@assets/home/educator20.png'),
    name: '梁侠',
    introduce: [
      '全国著名中学思想政治特级教师',
      '北京市思想政治学科带头人',
      '北京师范大学硕士生导师'
    ]
  },{
    avatar: require('@assets/home/educator21.png'),
    name: '田佩淮',
    introduce: [
      '全国著名中学地理特级教师',
      '国家级骨干教师',
      '全国首届优秀地理教师'
    ]
  },{
    avatar: require('@assets/home/educator22.png'),
    name: '张斌平',
    introduce: [
      '全国著名中学历史特级教师',
      '全国优秀教师',
      '北京市第五中学校长'
    ]
  },{
    avatar: require('@assets/home/educator23.png'),
    name: '诸才章',
    introduce: [
      '全国著名中学英语特级教师',
      '中国教育学会外语专业委员会会员',
      '浙江师范大学硕士生导师'
    ]
  }
]

export { layoutBanner, dropDownList, contList, educationList }
