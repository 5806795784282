import { useHistory } from 'react-router-dom'
import { Image, Button } from 'antd'
import { useEffect, useRef } from 'react';

const ContCard = ({
  item
}) => {
  const videoRef = useRef()
  const history = useHistory();

  const jumpDom = (url) => {
    const httpRegx = /^http(s*):\/\//
    if (!url) return
    if (httpRegx.test(url)) {
      window.location.href = url
    } else {
      history.push(url)
    }
  }

  useEffect(() => {
    const durationFun = (e) => {
      if (videoRef?.current) videoRef.current.play()
    }

    videoRef.current && videoRef.current?.addEventListener("loadedmetadata", durationFun);
    return () => {
      videoRef.current && videoRef.current?.removeEventListener("loadedmetadata", durationFun);
    }
  }, [])

  return (
    <div className="mt160 mt160">

      {item?.title && (
        <div className="fs56 fw500 font-medium text-color-footer lh72 tc mb24">{item?.title}</div>
      )}

      {item?.subtitle && (
        <div className="fs40 fw400 font-regular text-color lh40 tc mb136">{item?.subtitle}</div>
      )}

      <div className="flex">

        {item?.list?.map((el, i) => {
          if (el?.type === 'dest') {
            return (
              <div
                key={i}
                className={`flex1 ${i === 0 ? 'tl' : 'tc'}`}
              >
                <div className="ib tl">

                  <div className="fs36 fw500 font-medium text-color-footer mt24 mb16">
                    {el?.name}
                  </div>

                  {el?.content?.map((text, idex) => (
                    <div
                      key={i + idex}
                      className="fs28 fw400 lh56 text-color font-regular vam"
                    >
                      <i className="ib w10 h10 mr16 mt-5 bdrs8 vam" style={{ background: el?.color }} />
                      {text}
                    </div>
                  ))}

                  <Button
                    className="concard-btn w180 h50 fs24 lh24 fw400 font-regular text-color-white bdrs30 mt24"
                    style={{ background: el?.color }}
                    onClick={() => {
                      jumpDom(el?.path)
                    }}
                  >
                    查看详情
                  </Button>
                </div>
              </div>
            )
          } else if (el?.type === 'image') {
            return (
              <div key={i} className="flex1">
                <Image
                  width='100%'
                  src={el?.src?.default}
                  preview={false}
                />
              </div>
            )
          } else if (el?.type === 'video') {
            return (
              <video
                loop
                muted
                key={i}
                ref={videoRef}
                poster={el?.poster?.default}
                className="w-full pr"
              >
                <source src={el?.src?.default} type="video/mp4"></source>
              </video>
            )
          }
        })}
      </div>
    </div>
  )
}

export default ContCard
