(function () {
  function addIconfont() {
    if (!window.iconfontLoaded) {
      const link = document.createElement("link");

      link.onload = function () {
        window.iconfontLoaded = true;
      };
      link.onerror = function () {
        // console.error(errorMsg);
      };
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = "//at.alicdn.com/t/c/font_3122040_hj9dbluqbtj.css";

      document.head.appendChild(link);
    }
    window.removeEventListener("load", addIconfont);
  }
  window.addEventListener("load", addIconfont);
})();
