import { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import classNames from 'classnames'
import { Image, Row, Col } from 'antd'
import './index.less';

import FloatDiv from '@components/FloatDiv'
import ProCard from './ProCard'
import FeatCard from './FeatCard'

import { productData, iconList } from '@utils/productData'


const IconText = ({ item, type }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <div
      className={classNames("flex1 tc pointer", {
        'product-feat-item': type === item?.type
      })}
      onClick={() => {
        document.title = item?.documentTitle
        let pathname = location?.pathname?.split('/')
        pathname[pathname.length - 1] = item?.type
        pathname = pathname.join('/')
        history.push(pathname)
      }}
    >
      <Image
        className={classNames("w80 h80",{
          "procard-icon": type !== item?.type
        })}
        src={item?.src?.default}
        preview={false}
      />
      <div className="fs20 font-semibold lh30 text-color">{item?.title}</div>
    </div>
  )
}

const AllCarTem = ({ list }) => {
  return (
    <>
      <div className="pl260 pr260 product-cont pb60">
        {list?.proCard?.map((el, i) => (
          <div className={classNames("w-50 ib vat pb80", {
            'mt80': i === 0,
            'pt80': i > 0,
          })} key={i}>
            <FloatDiv>
              <ProCard item={el} />
            </FloatDiv>
          </div>
        ))}
      </div>
      {list?.featCard && (
        <div className="pl260 pr260 mb160">
          <div className="fs40 fw500 text-color-footer font-medium pt20 pb80 tc">
            更多实用功能
          </div>
          <Row gutter={[60, '60rem']}>
            {list?.featCard?.map((el, i) => (
              <Col span={8} className="h158 ib vat" key={i}>
                <FloatDiv>
                  <FeatCard item={el} />
                </FloatDiv>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  )
}

const Product = () => {
  const { type } = useParams();

  const [list, setList] = useState(productData[type])

  useEffect(() => {
    setList(productData[type])
  }, [type])

  return (
    <div className="product pr">
      <div className="flex product-feat">
        {iconList?.map((el, i) => (
          <IconText key={i} item={el} type={type} />
        ))}
      </div>
      {type === 'manager' && <AllCarTem list={list} />}
      {type === 'teacher' && <AllCarTem list={list} />}
      {type === 'student' && <AllCarTem list={list} />}
      {type === 'parent' && <AllCarTem list={list} />}
      {type === 'platform' && <AllCarTem list={list} />}
      {type === 'content' && <AllCarTem list={list} />}
    </div>
  );
}

export default Product
